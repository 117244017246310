import styled from 'styled-components'

import LogoComponent from 'src/assets/images/vectors/logo.svg'

export const Header = styled.header`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.level4};
  width: 100%;
  height: 5rem;
  background: ${({ theme }) => theme.white};
  box-shadow: 0 4px 24px ${({ theme }) => theme.boxShadow};

  ${({ theme }) => theme.mq.tablet} {
    height: 8rem;
  }
`

export const Logo = styled(LogoComponent)`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.level4};
  height: 3rem;

  ${({ theme }) => theme.mq.tablet} {
    height: 5rem;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 150rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 3.7rem;

  .logo {
    height: 3rem;

    ${({ theme }) => theme.mq.tablet} {
      height: 5rem;
    }
  }
`
