import React from 'react'

import * as S from './footer.styles'

import Facebook from 'src/assets/images/vectors/facebook.svg'
import Instagram from 'src/assets/images/vectors/instagram.svg'
import { Link } from 'gatsby'
import FOOTER_NAVIGATION_CONFIG from './config'

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => (
  <S.Footer>
    <S.Location>
      <S.Header>Lokalizacja</S.Header>
      <S.Paragraph>Gorczyn 77</S.Paragraph>
      <S.Paragraph>98-100 Łask</S.Paragraph>
      <S.Paragraph>Poland</S.Paragraph>
    </S.Location>
    <S.OpeningHours>
      <S.Header>Godziny otwarcia</S.Header>
      <S.Paragraph>poniedziałek-piątek</S.Paragraph>
      <S.Paragraph>9:00 - 17:00</S.Paragraph>
      <S.Paragraph>sobota-niedziela</S.Paragraph>
      <S.Paragraph>10:00 - 15:00</S.Paragraph>
    </S.OpeningHours>
    <S.Navigation>
      <S.Header>Na skróty</S.Header>
      {FOOTER_NAVIGATION_CONFIG.map(({ name, url }) => (
        <S.Paragraph key={name}>
          <Link to={url}>{name}</Link>
        </S.Paragraph>
      ))}
    </S.Navigation>
    <S.Help>
      <S.Header black>Pomoc</S.Header>
      <S.Phone href='tel:+48609731567'>+48 609 731 567</S.Phone>
      <S.Phone href='tel:+48725116146'>+48 725 116 146</S.Phone>
      <S.Email href='mailto:jmcarcompany84@gmail.com'>jmcarcompany84@gmail.com</S.Email>
    </S.Help>
    <S.Copyright>
      <S.Paragraph>
        &copy; {new Date().getFullYear()} jmcarcompany. All rights reserved. Made by{' '}
        <a className='creator' href='https://www.linkedin.com/in/kacper-%C5%82ochowski/' rel='author'>
          Pretty Simple
        </a>
      </S.Paragraph>
      <S.Social>
        <a href='https://www.facebook.com/jmcarcompany/'>
          <Facebook />
        </a>
        <a href='https://www.instagram.com/samochodyzachodniejmcc/?fbclid=IwAR3l6xcfIBXSSM_yM5d6s_9ywqcXss70-94KVbyGFzXgU9OuQEWhXDQ8bNk'>
          <Instagram />
        </a>
      </S.Social>
    </S.Copyright>
  </S.Footer>
)

export default Footer
