import styled from 'styled-components'

interface LineProps {
  isOpen: boolean
}

export const Container = styled.div`
  position: relative;
`

export const Menu = styled.p`
  position: absolute;
  top: 50%;
  left: -4rem;
  color: ${({ theme }) => theme.red};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.xs};
  transform: translateY(-50%);

  ${({ theme }) => theme.mq.tablet} {
    display: none;
  }
`

export const Hamburger = styled.div`
  position: relative;
  right: -0.5rem;
  z-index: ${({ theme }) => theme.zIndex.level4};
  width: calc(2rem + 1rem);
  height: calc(1.4rem + 1rem);
  padding: 0.5rem;
  overflow: hidden;
  cursor: pointer;

  ${({ theme }) => theme.mq.tablet} {
    display: none;
  }
`

export const Line = styled.span<LineProps>`
  position: absolute;
  width: calc(100% - 1rem);
  height: 0.2rem;
  background: ${({ theme }) => theme.red};
  border-radius: 2rem;
  transform-origin: center;
  transition: ${({ isOpen }) => (isOpen ? 'all 0.3s ease' : 'all 0.3s 0.8s ease')};

  :nth-child(1) {
    top: 0.5rem;
    transform: ${({ isOpen }) => (isOpen ? 'translateY(6px) rotate(45deg)' : 'translateY(0) rotate(0)')};
  }

  :nth-child(2) {
    top: 50%;
    transform: ${({ isOpen }) => (isOpen ? 'translate(calc(100% + 0.5rem), -50%)' : 'translate(-0.5rem, -50%)')};
    opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
  }

  :nth-child(3) {
    bottom: 0.5rem;
    transform: ${({ isOpen }) => (isOpen ? 'translateY(-6px) rotate(-45deg)' : 'translateY(0) rotate(0)')};
  }

  ${({ theme }) => theme.mq.tablet} {
    display: none;
  }
`
