import React, { useEffect, useState } from 'react'

import * as S from './opinion.styles'

import CommentIcon from 'src/assets/images/vectors/comment.svg'

interface OpinionProps {}

const Opinion: React.FC<OpinionProps> = () => {
  const [isMobile, setIsMobile] = useState(false)

  const mobileLink =
    'https://www.google.com/search?q=jmcarcompany&sxsrf=ALiCzsahOB8gxcViFwDNdQ1Iab6Zd3tK5g%3A1660064399475&ei=j5LyYtjDHMiOwPAPs5q24Ak&oq=jmcarcompany&gs_lcp=ChNtb2JpbGUtZ3dzLXdpei1zZXJwEAMyBwgAEEcQsAMyBwgAEEcQsAMyBwgAEEcQsAMyBwgAEEcQsAMyBwgAEEcQsAMyBwgAEEcQsAMyBwgAEEcQsAMyBwgAEEcQsANKBAhBGABQAFgAYKoJaAFwAXgAgAEAiAEAkgEAmAEAyAEIwAEB&sclient=mobile-gws-wiz-serp#wptab=s:H4sIAAAAAAAAAONgVuLVT9c3NMwwNqwwNS6resRowS3w8sc9YSn9SWtOXmPU5OIKzsgvd80rySypFJLmYoOyBKX4uVB18uxi4vfJT07MCcjPDEoty0wtL17EqphYWpKvkJ2fm1mskJWrkJxYpJCcn1uQmFepUARRAwAuXX0MggAAAA'
  const desktopLink =
    'https://www.google.com/search?q=jmcarcompany&sxsrf=ALiCzsbH8fnALtXKkiqTbO3Czr2xY_Yoyw%3A1660063600841&ei=cI_yYvr_MuuArwTthp7IDQ&oq=jmc&gs_lcp=Cgdnd3Mtd2l6EAMYADIECCMQJzILCC4QgAQQxwEQrwEyBQgAEIAEMgcIABCABBAKMgsILhCABBDHARCvATIFCAAQgAQyCwguEIAEEMcBENEDMgUIABCABDIFCC4QgAQyBQgAEIAEOgcIABBHELADOgsILhDHARDRAxCRAjoICC4Q1AIQkQI6BQgAEJECSgQIQRgASgQIRhgAUPkGWJMKYM8QaAFwAXgAgAFniAGsApIBAzIuMZgBAKABAcgBCMABAQ&sclient=gws-wiz#lrd=0x471a47acb38172d3:0xa9634349ed251967,1,,,'

  useEffect(() => {
    setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
  }, [])

  return (
    <S.Opinion href={isMobile ? mobileLink : desktopLink} rel='noopener' target='_blank'>
      <S.Paragraph>Opinie</S.Paragraph>
      <CommentIcon />
    </S.Opinion>
  )
}

export default Opinion
