import styled, { css } from 'styled-components'

interface HeaderProps {
  black?: boolean
}

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  max-width: 150rem;
  min-height: 100px;
  margin: 0 auto;
  padding: 7rem 3.7rem 4rem;
  color: ${({ theme }) => theme.darkgray};

  ${({ theme }) => theme.mq.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10rem 3.7rem 4rem;
  }
`

export const Location = styled.div`
  margin-bottom: 4rem;

  ${({ theme }) => theme.mq.bigTablet} {
    margin-right: 14rem;
  }
`
export const OpeningHours = styled.div`
  margin-bottom: 4rem;

  ${({ theme }) => theme.mq.bigTablet} {
    margin-right: 14rem;
  }
`

export const Navigation = styled.div`
  display: none;

  ${({ theme }) => theme.mq.tablet} {
    display: initial;
  }
`

export const Help = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 100%;
  text-align: right;

  ${({ theme }) => theme.mq.tablet} {
    align-self: flex-start;
    width: initial;
    /* margin-left: auto; */
  }

  ${({ theme }) => theme.mq.bigTablet} {
    margin-left: auto;
  }
`

export const Header = styled.h3<HeaderProps>`
  position: relative;
  display: inline-block;
  margin-bottom: 2.2rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};

  ::after {
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 9rem;
    height: 0.2rem;
    background: ${({ theme }) => theme.red};
    content: '';

    ${({ black }) =>
      black &&
      css`
        background: ${({ theme }) => theme.darkgray};
        left: unset;
        right: 0;
      `};
  }
`
export const Phone = styled.a`
  color: ${({ theme }) => theme.red};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.m};
  text-decoration: none;

  ${({ theme }) => theme.mq.phone} {
    font-size: ${({ theme }) => theme.font.size.headers.l};
  }

  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ theme }) => theme.font.size.headers.m};
  }

  ${({ theme }) => theme.mq.bigTablet} {
    font-size: ${({ theme }) => theme.font.size.headers.l};
  }
`

export const Paragraph = styled.p`
  margin-bottom: 1rem;

  :last-child {
    margin-bottom: 0;
  }

  a {
    color: ${({ theme }) => theme.darkgray};
    text-decoration: none;

    &.creator {
      text-decoration: underline;
    }
  }
`

export const Email = styled.a`
  position: relative;
  margin-top: 3.6rem;
  color: ${({ theme }) => theme.darkgray};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  text-decoration: none;

  ::before {
    position: absolute;
    top: -2.6rem;
    right: 0;
    width: 100%;
    height: 1.6rem;
    background: ${({ theme }) => theme.lightgray};
    content: '';
  }

  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ theme }) => theme.font.size.headers.xs};
  }

  ${({ theme }) => theme.mq.bigTablet} {
    font-size: ${({ theme }) => theme.font.size.headers.s};
  }
`

export const Copyright = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8rem;

  p {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mq.tablet} {
    flex-basis: 100%;
  }
`

export const Social = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 2rem;

  a {
    :first-child {
      margin-bottom: 1.9rem;
    }
  }

  ${({ theme }) => theme.mq.tablet} {
    flex-direction: row;

    a {
      :first-child {
        margin: 0 3rem;
      }
    }
  }
`
