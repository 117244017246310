import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

interface NavigationProps {
  isOpen: boolean
}

export const Navigation = styled.nav<NavigationProps>`
  position: fixed;
  top: 5rem;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.level3};
  width: 100%;
  height: calc(100% - 5rem);
  padding: 13rem 0 2.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  background: ${({ theme }) => theme.white};
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: ${({ isOpen }) => (isOpen ? 'all 0.3s ease' : 'all 0.3s 0.8s ease')};
  transition-property: opacity, transform;

  ${({ theme }) => theme.mq.tablet} {
    position: relative;
    top: unset;
    left: unset;
    width: auto;
    height: 8rem;
    padding: 0;
    transform: unset;
    opacity: 1;
    transition: unset;
  }
`

export const NavigationBackground = styled.div`
  position: absolute;
  top: 13rem;
  right: -3rem;
  width: 22rem;
  height: 32rem;
  object-fit: cover;

  ${({ theme }) => theme.mq.tablet} {
    display: none;
  }
`

export const List = styled.ul<NavigationProps>`
  max-width: 108rem;
  margin: 0 auto;
  padding: 0 3.7rem;
  transform: translateY(${({ isOpen }) => (isOpen ? '0' : '10%')});
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: ${({ isOpen }) => (isOpen ? 'all 0.5s 0.3s ease' : 'all 0.5s 0s ease')};
  transition-property: opacity, transform;

  ${({ theme }) => theme.mq.tablet} {
    display: flex;
    align-items: center;
    max-width: initial;
    height: 100%;
    margin: 0;
    padding: 0;
    transform: unset;
    opacity: 1;
    transition: unset;
  }
`

export const Item = styled.li`
  margin-bottom: 2rem;

  :last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mq.tablet} {
    margin-right: 3rem;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mq.bigTablet} {
    margin-right: 5rem;
  }
`

export const Link = styled(GatsbyLink)`
  position: relative;
  color: ${({ theme }) => theme.darkgray};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.l};
  text-transform: capitalize;
  text-decoration: none;

  &.active {
    color: ${({ theme }) => theme.red};
  }

  ${({ theme }) => theme.mq.tablet} {
    font-weight: ${({ theme }) => theme.font.weight.semibold};
    font-size: ${({ theme }) => theme.font.size.headers.s};

    &.active {
      ::after {
        position: absolute;
        bottom: -0.4rem;
        left: 0;
        width: 100%;
        height: 0.3rem;
        background: ${({ theme }) => theme.red};
        content: '';
      }
    }
  }
`
