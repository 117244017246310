import styled from 'styled-components'

export const Opinion = styled.a`
  position: fixed;
  right: 0;
  bottom: 20%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3.7rem;
  height: 10.3rem;
  color: ${({ theme }) => theme.white};
  text-decoration: none;
  background: ${({ theme }) => theme.red};

  svg {
    position: absolute;
    bottom: 1rem;
    width: 2rem;
    margin-top: 2rem;
  }
`
export const Paragraph = styled.p`
  margin-bottom: 2rem;
  transform: rotate(-90deg);
  transform-origin: center;
`
