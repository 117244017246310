interface FooterNavigationProps {
  name: string
  url: string
}

const FOOTER_NAVIGATION_CONFIG: FooterNavigationProps[] = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'O nas',
    url: '/kim-jestesmy',
  },
  {
    name: 'Oferta',
    url: '/oferta',
  },
  {
    name: 'Kontakt',
    url: '/kontakt',
  },
]

export default FOOTER_NAVIGATION_CONFIG
