import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'

import { MENU_LINKS } from './config'

import * as S from './navigation.styles'
import Button from 'src/components/button/button'

interface NavigationProps {
  isOpen: boolean
}

const Navigation: React.FC<NavigationProps> = ({ isOpen }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "navigationLogo.png" }) {
        childImageSharp {
          fluid(maxHeight: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <S.Navigation isOpen={isOpen}>
      <S.NavigationBackground>
        <Image fluid={data.file.childImageSharp.fluid} />
      </S.NavigationBackground>
      <S.List isOpen={isOpen}>
        {MENU_LINKS.map(({ name, url }) => (
          <S.Item key={name}>
            <S.Link to={url} activeClassName='active'>
              {name}
            </S.Link>
          </S.Item>
        ))}
        <li>
          <Button path='/kontakt'>Kontakt</Button>
        </li>
      </S.List>
    </S.Navigation>
  )
}

export default Navigation
