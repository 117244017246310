import React from 'react'

import * as S from './hamburger.styles'

interface HamburgerProps {
  isOpen: boolean
  onClick: () => void
}

export const Habmurger: React.FC<HamburgerProps> = props => {
  const { isOpen } = props

  return (
    <S.Container>
      <S.Menu>Menu</S.Menu>
      <S.Hamburger {...props}>
        <S.Line isOpen={isOpen} />
        <S.Line isOpen={isOpen} />
        <S.Line isOpen={isOpen} />
      </S.Hamburger>
    </S.Container>
  )
}
