interface MenuItems {
  name: string
  url: string
}

export const MENU_LINKS: MenuItems[] = [
  { name: 'Oferta', url: '/oferta' },
  { name: 'Home', url: '/' },
  { name: 'O nas', url: '/kim-jestesmy' },
]
