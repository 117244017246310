import React, { useState } from 'react'
import { Link } from 'gatsby'

import * as S from './header.styles'

import Navigation from './navigation'
import { Habmurger } from './hambuger'

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const [isMenuOpen, setMenuState] = useState(false)

  const toggleMobileMenu = () => {
    setMenuState(!isMenuOpen)
  }

  typeof window !== 'undefined' &&
    window.addEventListener('resize', () => {
      window.innerWidth >= 768 && isMenuOpen && toggleMobileMenu()
    })

  return (
    <S.Header>
      <S.Wrapper>
        <Link className='logo' to='/'>
          <S.Logo />
        </Link>
        <Habmurger onClick={toggleMobileMenu} isOpen={isMenuOpen} />
        <Navigation isOpen={isMenuOpen} />
      </S.Wrapper>
    </S.Header>
  )
}

export default Header
