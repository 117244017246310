import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { theme } from 'src/assets/styles/theme'
import GlobalStyles from 'src/assets/styles/globalStyles'

import CookieConsent from '../cookieConsent/cookieConsent'
import Header from 'src/components/header/header'
import Footer from 'src/components/footer/footer'
import Opinion from '../opinion/opinion'

interface Props {}

const Layout: React.FC<Props> = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <CookieConsent />
    <Header />
    <main>{children}</main>
    <Footer />
    <Opinion />
  </ThemeProvider>
)

export default Layout
